[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: grey;
  --amplify-components-field-label-color: white;
  --amplify-components-text-color: white;
  --amplify-components-input-color: white;
  --amplify-components-button-background-color: grey;
  --amplify-components-input-focus-border-color: white;
  --amplify-components-heading-color: white;
  --amplify-components-button-border-color: grey;
  --amplify-components-authenticator-modal-background-color: black;
  --amplify-components-authenticator-router-background-color: black;
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px black;
  --amplify-components-tabs-item-active-border-color: black;
  --amplify-components-tabs-item-color: white;
  --amplify-components-tabs-item-active-color: black;
  --amplify-components-button-link-color: white;
  --amplify-components-tabs-item-active-color: white;
  --amplify-components-tabs-item-background-color: grey;
  --amplify-components-tabs-item-color: black;
}

[data-amplify-authenticator] button:hover {
  background-color: white;
  color: black;
  border-color: white;
}

[data-amplify-authenticator] a:hover {
  color: black;
}

.super-app-theme--header {
  background-color: white;
}